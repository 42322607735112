<template>
  <div class="container">

    <!-- <div>
        <div class="campus_schedule_search">
            <van-cell-group>
                <van-cell @click="handleClick('showCalendar')" title="时间选择" is-link :value="`${dateValue.length === 0 ? '' : `${dayjs(dateValue[0]).format('MM/DD')} - ${dayjs(dateValue[1]).format('MM/DD')}`}`" />
            </van-cell-group>
        </div>
    </div> -->

    <div class="campus_schedule_search">
        <van-cell-group>
            <!-- <van-cell @click="handleClick('studioShowPicker',1)" title="上课校区" is-link :value="studio.label" /> -->
            <van-cell @click="handleClick('showCalendar')" title="上课时间" is-link :value="`${dateValue.length === 0 ? '' : `${dayjs(dateValue[0]).format('MM/DD')} - ${dayjs(dateValue[1]).format('MM/DD')}`}`" />
        </van-cell-group>
    </div>

    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <van-col v-for="(item,index) in list" :key="index" span="24">
        <div @click="toDetail(item)" class="list-item van-hairline--bottom">
          <div class="list-item-hd">
            <div class="list-item-title">{{ item.book_weeks }}</div>
            <div class="list-item-txt">{{ item.class_date | formatEnd }}</div>
            <div class="list-item-txt">{{ item.class_start }}</div>
          </div>
          <div class="list-item-bd">
            <div class="list-item-title">{{ item.course_name }}</div>
            <!-- <div class="list-item-txt">{{ item.course_studio.studio_name }} • {{ item.room_name }}</div> -->
            <div class="list-item-txt">{{ item.course_studio.studio_name }}</div>
            <div class="list-item-txt list-item-txt-flex">
              <van-icon style="margin-right:5px" class-prefix="icon" size="12" name="PersonalOutline" />
              <span class="list-item-txt-teacher van-ellipsis">{{ item.course_teacher.teacher_name }}</span>

              <van-icon style="margin:0 5px" class-prefix="icon" size="12" name="HistoryOutline" />
              <span class="list-item-txt-hour van-ellipsis">{{ item.course_hour }} </span>
              
              <!-- <van-icon style="margin:0 5px" class-prefix="icon" size="12" name="HomeOutline" />
              <span class="list-item-txt-room van-ellipsis">{{ item.room_name }} </span> -->
            </div>
          </div>
          <div class="list-item-ft">
            <div class="list-item-status">{{ item.book_status }}</div>
            <!-- <van-button type="primary" size="small" :to="'/student/ask-leave?book_id=' + item.book_id">去请假</van-button> -->
          </div>
        </div>
      </van-col>
    </van-list>

    <van-calendar v-model="showCalendar" :show-confirm="false" color="#00cca2" :default-date="defaultDate" :min-date="minDate" :max-date="maxDate" type="range" @confirm="onConfirmCalendar" />
  </div>
</template>
<script>
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import PageMixin from '@/mixins/page'
import { Cell, CellGroup, DatetimePicker, Picker, Calendar, Checkbox, CheckboxGroup, Field, Tag } from 'vant'

export default {
  name: 'StudentBooking',
  filters: {
    formatEnd(value) {
      return value && value.slice(-5)
    }
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      showCalendar:false,
      teacherShowPicker:false,
      studioShowPicker:false,
      studioColumns:[],
      studio: {},
      teacher:{},
      teachers:[],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      // dateValue:[dayjs().add(-180,'day').format('YYYY-MM-DD'),dayjs().format('YYYY-MM-DD')],
      // defaultDate:[dayjs().add(-180,'day').$d, dayjs().$d],
      dateValue: [],
      defaultDate: [],
      minDate: dayjs().add(-5,'year').$d,
      maxDate: dayjs().$d,
      pageCurrent: 1,
      start_date: '',
      end_date: '',
    }
  },
  components: {
      [Tag.name]:Tag,
      [Field.name]:Field,
      [Cell.name]:Cell,
      [Picker.name]:Picker,
      [Checkbox.name]:Checkbox,
      [CheckboxGroup.name]:CheckboxGroup,
      [Calendar.name]:Calendar,
      [CellGroup.name]:CellGroup,
      [DatetimePicker.name]:DatetimePicker,
  },
  created() {

  },
  methods: {
    dayjs,
    handleClick(name,num){
        this[name] = true
    },
    onListLoad() {
      this.onPage()
    },
    onConfirmCalendar(date){
        this.dateValue = [dayjs(date[0]).format('YYYY-MM-DD'),dayjs(date[1]).format('YYYY-MM-DD')]
        this.showCalendar = false
        this.list = [];
        this.pageCurrent=1
        this.start_date = this.dateValue[0]
        this.end_date = this.dateValue[1]
        this.onPage()
    },
    onPage() {
      this.$api.profile_schedulelist({ 'per-page': this.pageSize, page: this.pageCurrent, start_date: this.start_date, end_date: this.end_date }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    },
    toDetail(item){
      this.$router.push({path:'/student/schedule/detail',query:{book_id:item.book_id}})
    },
  }
}
</script>

<style lang="less" scoped>

  .list{
    &-item{
      background: #fff;
      padding: 10px 15px;
      display: flex;
      justify-content: flex-start;
      &-hd{
        width: 50px;
      }
      &-bd{
        flex: 1;
      }
      &-status{
          font-size: 12px;
      }
      &-title{
        font-size: 14px;
        font-weight: bold;
        color: #333;
        margin-bottom: 5px;
      }
      &-txt{
        line-height: 1.8;
        font-size: 12px;
        color: #666;
        &-flex{
          display: flex;
          align-items: center;
        }
        &-room{
          display: inline-block;
          max-width: 120px;
          overflow: hidden;
        }
        &-teacher{
          display: inline-block;
          width: 60px;
          overflow: hidden;
        }
        &-hour{
          display: inline-block;
          width: 90px;
          overflow: hidden;
        }
      }
      &-ft{
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
</style>

